interface SEOProps {
  title: string;
  description: string;
  image: string;
  url: string;
  keywords: string[];
  language?: string;
  author?: string;
  type?: string;
}

export function SEO({ 
  title, 
  description, 
  image, 
  url, 
  keywords,
  language = 'en',
  author = 'Your Name',
  type = 'website'
}: SEOProps) {
  return (
    <>
      {/* Essential Meta Tags */}
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(', ')} />
      <meta name="author" content={author} />
      <meta name="language" content={language} />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={url} />

      {/* Open Graph Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />

      {/* JSON-LD Schema */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": title,
          "description": description,
          "url": url,
          "image": image,
          "author": {
            "@type": "Person",
            "name": author
          }
        })}
      </script>
    </>
  );
} 