import { FC } from 'react'

import gifDescribeFunction from '../assets/images/gif_describe_function.gif'

interface Update {
  id: string
  title: string
  date: string
  description: string
  imageUrl: string
}

const updates: Update[] = [
  {
    id: '1',
    title: 'Generate Function Description',
    date: '2024-11-13',
    description: 'You can now generate function description using LLM. The description is cached in memory as well.',
    imageUrl: gifDescribeFunction
  },
]

export const Updates: FC = () => {
  return (
    <div className="min-h-screen bg-slate-50 py-16">
      <div className="container mx-auto px-4">
        <div className="text-center">
          <h1 className="text-4xl font-bold mb-12">Product Updates</h1>
        </div>
        
        <div className="space-y-16 max-w-[600px] mx-auto">
          {updates.sort((a, b) => Number(b.id) - Number(a.id)).map(update => (
            <div key={update.id} className="bg-white rounded-lg shadow-md overflow-hidden">
              <div className="flex flex-col">
                <div className="p-8">
                  <div className="flex items-center mb-4">
                    <h2 className="text-2xl font-bold">{update.title}</h2>
                    <time className="ml-4 text-sm text-accent">
                      {new Date(update.date).toLocaleDateString()}
                    </time>
                  </div>
                  <p className="text-accent mb-4">{update.description}</p>
                  <img
                    src={update.imageUrl}
                    alt={update.title}
                    className="w-full h-64 object-cover"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}